<template>
  <header :style="{ background: background }">
    <div class="header">
      <div class="content">
        <div class="logo">
          <router-link to="/" @click="$router.push('/')">
            <span class="icon">RUBYSTAR GLOBAL LTD</span>
          </router-link>
        </div>
        <div :class="['menu', { exhibit: isOpenMenu }]">
          <div :class="[isOpenMenu ? 'colse' : 'open']" @click="isOpenMenu = !isOpenMenu" />
          <div
            :class="['menu_item', { menu_item_focus: $route.path === item.path }]"
            v-for="(item, index) in menu"
            :key="index"
          >
            <router-link :to="item.path">{{ item.value }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isOpenMenu: false,
      background: "#FBF9F9",
    };
  },
  methods: {
    handleExternalLink(externalUrl) {
      window.location.href = externalUrl;
      // 阻止vue-router的默认行为
      return false;
    },
  },
  watch: {
    "$route.path": function (newVal) {
      if (newVal === "/") {
        this.background = "#FBF9F9";
      } else {
        this.background = "white";
      }
      this.isOpenMenu = false;
    },
  },
  computed: {
    menu() {
      return [
        { name: "Home", value: this.$t("home"), path: "/" },
        { name: "ContactUs", value: this.$t("contact_us"), path: "/ContactUs" },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin pixel-ratio() {
  @media screen and (-webkit-min-device-pixel-ratio: 1),
    screen and (min--moz-device-pixel-ratio: 1) {
    @import "../assets/scss/sprite/header.scss";
    .menu {
      .colse {
        @include sprite($close);
      }
      .open {
        @include sprite($menu);
      }
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio: 2),
    screen and (min--moz-device-pixel-ratio: 2) {
    @import "../assets/scss/sprite/header@2x.scss";
    .menu {
      .colse {
        @include sprite($close-2x);
      }
      .open {
        @include sprite($menu-2x);
      }
    }
  }
}
header {
  @include pixel-ratio();
  border-bottom: 1px solid #f6f6f6;
  transition: all 0.3s;
  /**
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    // background-color: #e6dcf3;
  }**/
  .content {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    height: 70px;
    text-align: center;
    .logo {
      display: inline-block;
      font-size: 18px;
      flex: 1;
      text-align: left;
      .icon {
        display: inline-block;
        background-repeat: no-repeat;
        // width: 50px;
        height: 60px;
        line-height: 60px;
        font-weight: 800;
        font-size: 20px;
        vertical-align: middle;
        margin: 10px 0 10px 10px;
      }
      a {
        text-decoration: none;
        color: #433760;
      }
    }
    .menu {
      display: flex;
      .colse,
      .open {
        display: none;
        position: absolute;
        right: 25px;
        top: 21px;
      }
      .menu_item {
        text-align: center;
        flex: 1;
        margin: 0 20px;
        white-space: nowrap;
        border-top: 2px solid transparent;
        line-height: 70px;
        a {
          display: block;
          text-decoration: none;
          color: #333333;
          cursor: pointer;
          font-weight: 500;
          font-size: 16px;
          &:hover {
            color: #d64fff;
          }
        }
        &_focus {
          border-top: 2px solid #d64fff;
          a {
            color: #d64fff;
          }
        }
      }
    }
  }
  @media screen and (max-width: 800px) {
    .content {
      background: white;
      .menu {
        background: white;
        .colse,
        .open {
          display: block;
        }
        .menu_item {
          display: none;
        }
      }
      .exhibit {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        display: block;
        padding-top: 96px;
        .menu_item {
          display: block;
          text-align: start;
          border-top: 0px;
          border-bottom: 0.5px solid #e4e4e4;
          line-height: 48px;
          a {
            &:hover {
              color: #d91d22;
            }
          }
          &_focus {
            a {
              color: #d91d22;
            }
          }
        }
      }
    }
  }
}
</style>
