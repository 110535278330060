import Vue from 'vue';
import VueRouter from 'vue-router';


Vue.use(VueRouter);

const routes = [
  { path: '/', name: 'Home', component: () => import('@/views/Home.vue') },
  { path: '/WhoWeAre', name: 'WhoWeAre', component: () => import('@/views/WhoWeAre.vue') },
  { path: '/QuickApply', name: 'QuickApply', component: () => import('@/views/QuickApply.vue') },
  { path: '/Faq', name: 'Faq', component: () => import('@/views/Faq.vue') },
  { path: '/ContactUs', name: 'ContactUs', component: () => import('@/views/ContactUs.vue') },
  { path: '/UserTips', name: 'UserTips', component: () => import('@/views/UserTips.vue') },
];

const router = new VueRouter({
  mode: 'hash',
  // hash: 使用 URL hash 值来作路由。支持所有浏览器，包括不支持 HTML5 History Api 的浏览器。
  // history: 依赖 HTML5 History API 和服务器配置。查看 HTML5 History 模式。
  // abstract: 支持所有 JavaScript 运行环境，如 Node.js 服务器端。如果发现没有浏览器的 API，路由会自动强制进入这个模式。
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, form, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

export default router;
