function getGps() {
  return Promise.race([
    new Promise((resolve) => {
      setTimeout(() => resolve(), 3000);
    }),
    new Promise((resolve) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const longitude = position.coords.longitude.toFixed(5); // 获取经度
            const latitude = position.coords.latitude.toFixed(5); // 获取纬度
            window.localStorage.setItem('gps', `${longitude},${latitude}`);
            resolve();
          }, () => {
            resolve();
          }, {
            maximumAge: 5 * 60 * 1000,
          },
        );
      }
    }),
  ]);
}

export async function geolocation() {
  let gps = window.localStorage.getItem('gps') || '0,0';

  if (gps && gps !== '0,0') {
    getGps();
  } else {
    await getGps();
    gps = window.localStorage.getItem('gps') || '0,0';
  }

  return gps;
}

export default { geolocation };
